import { EditGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";

const ToolSetupEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"partNumberId"} />
    <InputGuesser source={"libelle"} />
    <InputGuesser source={"licenceLevel"} />
    <InputGuesser source={"sparePart"} />
  </EditGuesser>
);

export default ToolSetupEdit;
